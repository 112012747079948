import React from "react"
import styled from "styled-components"

import SEO from "./../components/seo"
import Profile from "./../components/profile"
import Footer from "../components/Footer"

const FAQ = () => {
  return (
    <>
      <SEO
        slug="/faq/"
        title="Roadmap | MTG Builders, a Deck Builder app"
        description="A modern MTG Deck Builder has to be constantly improved. Here there is a list of features I'm currently working on to elevate the deck building experience"
      />
      <Profile />
      <PageLayout>
        <MyText
          as={"h2"}
          style={{
            "--font-size": "5rem",
            "--margin-bottom": "0rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          MTG Builders
        </MyText>
        <MyText
          as={"h1"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "0rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          a MTG Deck Builder web app
        </MyText>
        <MyText
          as={"h2"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          to build the future of Magic
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.4rem",
            "--margin-bottom": ".7rem",
            /* "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black", */
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          Add explanation <GraphicText>&nbsp;i&nbsp;</GraphicText> in every
          page&nbsp;
          <GraphicCircle colore="verde" />{" "}
          <MyText
            as={"span"}
            style={{
              "--font-size": "1.4rem",
              "--margin-bottom": ".7rem",
              "--text-shadow": "0px 7px 4px black",
              "--text-shadow-hover": "0px 7px 4px black",
              /*             "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black", */
            }}
          >
            31 March 2021
          </MyText>
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.4rem",
            "--margin-bottom": ".7rem",
            /* "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black", */
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          View chosen cards also while in "Binder"&nbsp;
          <GraphicCircle colore="verde" />{" "}
          <MyText
            as={"span"}
            style={{
              "--font-size": "1.4rem",
              "--margin-bottom": ".7rem",
              "--text-shadow": "0px 7px 4px black",
              "--text-shadow-hover": "0px 7px 4px black",
              /*             "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black", */
            }}
          >
            25 April 2021
          </MyText>
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.4rem",
            "--margin-bottom": ".7rem",
            /* "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black", */
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          Test decks in a real MTG environment&nbsp;
          <GraphicCircle colore="rosso" />
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.4rem",
            "--margin-bottom": ".7rem",
            /* "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black", */
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          Import decks from external websites&nbsp;
          <GraphicCircle colore="rosso" />
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.4rem",
            "--margin-bottom": ".7rem",
            /* "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black", */
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          PWA&nbsp;
          <GraphicCircle colore="rosso" />
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.4rem",
            "--margin-bottom": ".7rem",
            /* "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black", */
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          Scatter and Arrange cards in Builder&nbsp;
          <GraphicCircle colore="verde" />{" "}
          <MyText
            as={"span"}
            style={{
              "--font-size": "1.4rem",
              "--margin-bottom": ".7rem",
              "--text-shadow": "0px 7px 4px black",
              "--text-shadow-hover": "0px 7px 4px black",
              /*             "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black", */
            }}
          >
            5 May 2021
          </MyText>
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.4rem",
            "--margin-bottom": "4.5rem",
            /* "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black", */
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          Restyling of the landing page&nbsp;
          <GraphicCircle colore="rosso" />
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.4rem",
            "--margin-bottom": "0.5rem",
            /* "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black", */
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          Request features in the app-feedback channel on Discord.
        </MyText>
        <Footer />
      </PageLayout>
    </>
  )
}

export default FAQ

const PageLayout = styled.div`
  background: radial-gradient(#555, #111);
  padding: 2rem;
  min-height: 100vh;
  width: 100%;
  color: #cccccc;

  display: flex;
  flex-direction: column;
  /*   justify-content: center; */
  align-items: center;
`

const MyText = styled.p`
  cursor: default;
  font-size: var(--font-size);
  color: #cccccc;
  margin-bottom: var(--margin-bottom);
  text-shadow: var(--text-shadow);
  text-align: center;
  max-width: 1200px;

  &:hover {
    text-shadow: var(--text-shadow-hover);
  }
`

const GraphicText = styled.span`
  border-radius: 50%;
  background-color: #cccccc;
  font-size: 22px;
  color: #000000;
  text-shadow: none;
`
const GraphicCircle = styled.div`
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background-color: ${props =>
    props.colore === "rosso" ? "#D6001C" : "#00892F"};
`
